<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage invoice</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Invoice
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="addInvoice()" class="mt-4 ml-2 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-add</v-icon>&nbsp;
                    Add invoice
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body ">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field label="Payer name, phone, email, transaction id" type="text" dense outlined
                                v-on:keyup.enter="searchInvoice()" v-model="search.name" />
                </v-col>

                <v-col cols="12" md="4">
                  <v-select label="Payment method" outlined dense :items="payment_methods" item-text="title"
                            item-value="value" v-on:keyup.enter="searchInvoice()" v-model="search.payment_method"></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu ref="menuDate" v-model="menuStartDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.start_date" label="View from date" readonly outlined dense clearable
                                    v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="search.start_date" outlined dense no-title @input="menuStartDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu ref="menuDate" v-model="menuEndDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.end_date" label="View up to date" readonly outlined dense clearable
                                    v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="search.end_date" outlined dense
                                   :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                   no-title @input="menuEndDate = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="8" class="text-right">
                  <v-btn @click="searchInvoice()" class="btn btn-primary btn-search w-35">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Bill From</th>
                  <th class="px-3 wrap-column">Bill to</th>
                  <th class="px-3 wrap-column">Due date</th>
                  <th class="px-3 wrap-column">Paid amount</th>
                  <th class="px-3 wrap-column">Remaining amount</th>
                  <th class="px-3 wrap-column">Discount amount</th>
                  <th class="px-3 wrap-column">Total amount</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="invoices.length > 0" v-for="(invoice, index) of invoices" :key="index" :class="invoice.due_past ? 'bg-color' : ''">
                  <td class="px-3 wrap-column">
                    <p>
                      <b>Payer name: </b> {{ invoice.payer_name}}
                    </p>
<!--                    <p>-->
<!--                      <b>Payer email: </b> {{ invoice.payer_email}}-->
<!--                    </p>-->
                    <p>
                      <b>Payer address: </b> {{ invoice.payer_address}}
                    </p>
<!--                    <p>-->
<!--                      <b>Payer phone: </b> {{ invoice.payer_phone}}-->
<!--                    </p>-->
                  </td>

                  <td class="px-3 wrap-column">
                    <p>
                      <b>Invoice to: </b> {{ invoice.invoiced_to}}
                    </p>
<!--                    <p>-->
<!--                      <b>Invoice email: </b> {{ invoice.invoice_email}}-->
<!--                    </p>-->
                    <p>
                      <b>Invoice address: </b> {{ invoice.invoice_address}}
                    </p>
<!--                    <p>-->
<!--                      <b>Invoice phone: </b> {{ invoice.invoice_phone}}-->
<!--                    </p>-->
                  </td>

                  <td class="px-3 wrap-column">
                    {{ invoice.formatted_due_date }}
                  </td>

                  <td class="px-3 wrap-column">
                    {{ invoice.currency_symbol }} {{ parseFloat(invoice.payment_amount) }}
                  </td>

                  <td class="px-3 wrap-column">
                    {{ invoice.currency_symbol }} {{ parseFloat(invoice.invoice_total) -
                  parseFloat(invoice.payment_amount)}}
                  </td>

                  <td class="px-3 wrap-column">
                    {{ invoice.currency_symbol }} {{ parseFloat(invoice.discount_amount) }}
                  </td>

                  <td class="px-3 wrap-column">
                    {{ invoice.currency_symbol }} {{ parseFloat(invoice.invoice_total) }}
                  </td>

                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown size="sm" variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  no-caret right no-flip>
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item" v-if="invoice.file_path">
                            <a @click="viewInvoicePdf(invoice.file_path.real)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-file-pdf"></i>
                                </span>
                              <span class="navi-text">View pdf</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="viewInvoice(invoice.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-eye"></i>
                                </span>
                              <span class="navi-text">View invoice</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="deleteInvoice(invoice.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-trash"></i>
                                </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                  <!--                  <td>{{invoice.payer_relation}}</td>-->
                </tr>
                <tr v-if="invoices.length == 0">
                  <td colspan="12" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination v-if="invoices.length > 0" @input="getAllInvoices" class="pull-right mt-7" v-model="page"
                            :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import InvoiceService from "@/services/invoice/InvoiceService";

const invoice = new InvoiceService();

export default {
  name: "AllInvoice",
  data() {
    return {
      invoices: [],
      total: null,
      perPage: null,
      page: null,
      menuStartDate: false,
      menuEndDate: false,
      search: {
        title: '',
        type: 'invoice',
        start_date: '',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      payment_methods: [
        {
          title: 'Bank',
          value: 'bank'
        },
        {
          title: 'Cash',
          value: 'cash'
        },
        {
          title: 'Khalti',
          value: 'khalti'
        },
        {
          title: 'Esewa',
          value: 'esewa'
        },
        {
          title: 'Connect Ips',
          value: 'connect_ips'
        },

      ],
    };
  },
  mounted() {
    this.getAllInvoices()
  },
  methods: {
    viewInvoicePdf(file_path) {
      window.open(file_path)
    },
    viewInvoice(id) {
      this.$router.push({ name: 'invoice-view', params: { id: id } });
    },
    searchInvoice() {
      this.page = 1;
      this.getAllInvoices()
    },
    getAllInvoices() {
      invoice.paginate(this.search, this.page).then(response => {
        this.invoices = response.data.data
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch(() => {
        this.invoices = [];
      })
    },

    addInvoice() {
      this.$router.push({ name: 'invoice-create' })
    },

    deleteInvoice(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            invoice.delete(id).then((response) => {
              if (response.data.status == "OK") {
                this.$snotify.success("Delete invoice successfully ");
                this.getAllInvoices()
              }
            }).catch(() => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },
  }
};
</script>
<style lang="css">
.bg-color {
  background-color: #f182823b !important;
}
tr:hover,
.schedule-data:hover tr,
.schedule-data:hover tr td,
.schedule-data:hover div td,
tbody>tr:nth-child(2n+1):hover,
.schedule-data:hover .schedule-data:nth-of-type(2n) .schedule-data-badge {
  background-color: #ff00003b !important;
}
</style>
